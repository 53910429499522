import { __awaiter } from "tslib";
import { Utils } from '../../../core/utils';
import * as _ from 'lodash';
import { QualActivityPostModalController } from './qual-activity-post-edit-modal/qual-activity-post-edit-modal.controller';
import { IscUIUtils, } from 'isc-ui';
import { IscDraftService } from 'isc-ui/dist/services/activity/draft.service';
import { DateTime } from 'luxon';
const editModal = "<isc-modal-dialog class=\"completion-modal buttons-left\">\n  <isc-modal-close>\n      <isc-button class=\"icon-button\" ng-click=\"vm.cancel()\">\n        <isc-button-icon>\n          <md-icon md-svg-src=\"/images/button-icons/Remove.svg\"></md-icon>\n        </isc-button-icon>\n      </isc-button>\n    </isc-modal-close>\n  <isc-modal-content ng-if=\"vm.canRedirectToWizard\">\n    <div class=\"title\">Redirect to the Admin interface</div>\n    <span>This post's content can only be edited in the Admin (via desktop).</span>\n  </isc-modal-content>\n  <isc-modal-content ng-if=\"!vm.canRedirectToWizard\">\n      <div class=\"title\">Edit only available on Admin desktop</div>\n      <span>This post's content can only be edited in the Admin (via desktop). Please switch devices to edit.</span>\n    </isc-modal-content>\n  <isc-modal-actions layout=\"row\" layout-align=\"space-between\">\n    <isc-button class=\"flat primary cancel\" ng-click=\"vm.cancel()\">\n      <isc-button-label>Cancel</isc-button-label>\n    </isc-button>\n    <isc-button ng-if=\"vm.canRedirectToWizard\" class=\"primary ok\" ng-click=\"vm.ok()\" layout-align=\"end\">\n      <isc-button-label>OK!</isc-button-label>\n    </isc-button>\n  </isc-modal-actions>\n</isc-modal-dialog>";
export class QualActivityPostController {
    constructor($stateParams, $state, $rootScope, forumService, conversationService, serverConstants, conversationEventsService, featureToggleService, constantsfactory, participantFactory, squareFactory, authService, $mdDialog, mediaservice, mappingService, muxService, fileStorageService, iscStimuliTypeService, $q, activitySequenceHelperService) {
        this.$stateParams = $stateParams;
        this.$state = $state;
        this.$rootScope = $rootScope;
        this.forumService = forumService;
        this.conversationService = conversationService;
        this.serverConstants = serverConstants;
        this.conversationEventsService = conversationEventsService;
        this.featureToggleService = featureToggleService;
        this.constantsfactory = constantsfactory;
        this.participantFactory = participantFactory;
        this.squareFactory = squareFactory;
        this.authService = authService;
        this.$mdDialog = $mdDialog;
        this.mediaservice = mediaservice;
        this.mappingService = mappingService;
        this.muxService = muxService;
        this.fileStorageService = fileStorageService;
        this.iscStimuliTypeService = iscStimuliTypeService;
        this.$q = $q;
        this.activitySequenceHelperService = activitySequenceHelperService;
        this.replyActive = false;
        this.stimuli = [];
        this.attachments = [];
        this.newStimulusFiles = [];
        this.newAttachmentFiles = [];
        this.buttonClicked = false;
        this.isComponentDestroyed = false;
        this.shouldShowConversationAttachment = this.featureToggleService.shouldShowConversationAttachment;
        this.allQuestionsAnswered = false;
        this.requiresNewSave = false;
        this.isTopic = false;
        this.saveButtonClicked = false;
        this.isEditMode = false;
        this.replyContentWhileSavingDisabled = false;
        this.isSequencedActivity = false;
        this.addedStimulusFiles = [];
        this.addedAttachmentFiles = [];
        this.removedStimulusFiles = [];
        this.removedAttachmentFiles = [];
        this.lastBlurTimestamp = (new Date()).getTime();
    }
    $onInit() {
        return __awaiter(this, void 0, void 0, function* () {
            this.isComponentDestroyed = false;
            this.isTopic = this.item.Guid === this.item.ParentMessage && !this.item.IsReadonly;
            this.conversationEditorConfig = {
                showTitle: false,
                showTextEditor: true,
                showCaptionEditor: true,
                saveLabel: this.saveButtonText,
                actionButtonsDisabled: this.buttonClicked,
                uploadConfig: {
                    showImageUpload: true,
                    showVideoUpload: true,
                    showVideoLink: true,
                    showAttachment: true,
                    maxStimuli: this.serverConstants.validationConstants.conversationStimuliMaxNo,
                    maxAttachments: this.serverConstants.validationConstants.conversationAttachmentMaxNo,
                },
            };
            this.visualFocusedConversationConfig = {
                isColumnLayout: true,
                showTextEditor: false,
                uploadConfig: {
                    showImageUpload: false,
                    showVideoLink: false,
                    showVideoUpload: false,
                    showAttachment: false,
                },
            };
            this.editVisualFocusedConversationConfig = {
                isColumnLayout: true,
                showTextEditor: false,
                saveLabel: this.saveButtonText,
                uploadConfig: {
                    showImageUpload: true,
                    showVideoUpload: true,
                    showVideoLink: true,
                    showAttachment: false,
                    maxStimuli: this.serverConstants.validationConstants.conversationStimuliMaxNo,
                    maxAttachments: this.serverConstants.validationConstants.conversationAttachmentMaxNo,
                },
            };
            this.isDiary = this.contributionType === this.serverConstants.activityContributionTypeConstants.diary;
            yield this.initProbeQuestionProperties();
            this.isOpeningPost = this.item.ParentMessage === this.item.Guid;
            this.isScoutDiscussionType = IscUIUtils.isActivityScoutDiscussionType(this.activityType, this.serverConstants);
            this.isMobile = this.mediaservice.isMobile;
            this.postStimuli = this.mappingService.mapConversationStimuliForConversationEditor(this.item.Stimuli);
            if (this.item.IsOneByOne) {
                this.initAnsweredVsUnansweredQuestions();
            }
            yield this.setSaveLabel();
            this.isReplyWithAnswerRequired = this.item.Type === this.serverConstants.conversationElementTypeConstants.reply && this.item.AnswerRequired;
            this.author = {
                username: this.item.Username,
                picture: this.avatar,
                link: this.getAuthorPublicProfileUrl(),
            };
            this.stimulitypes = this.iscStimuliTypeService.getTypes();
            IscDraftService.subscribeToDraftFileChanges(this.getDraftIdentifier(), this.onDraftFileChanges);
            if (this.item.IsOneByOne && this.shouldUpdateOnInit()) {
                this.autosave();
            }
            this.checkPictureStimuli();
        });
    }
    checkPictureStimuli() {
        const dt = DateTime.fromISO(this.item.DateCreated).plus({ minutes: 5 });
        if (dt < DateTime.utc()) {
            return;
        }
        if (this.isVisualFocusedOpeningPost()) {
            if (this.postStimuli && this.postStimuli.length !== 0) {
                this.postStimuli.forEach((stimulus) => {
                    if (stimulus.type === this.serverConstants.conversationStimuliTypeConstants.image
                        && stimulus.IsBroken !== true) {
                        this.fileStorageService.checkStimulusUrl(stimulus.url, (broken) => {
                            stimulus.IsBroken = broken;
                        });
                    }
                });
            }
        }
        else {
            if (this.item.Stimuli && this.item.Stimuli.length !== 0) {
                this.item.Stimuli.forEach((stimulus) => {
                    if (stimulus.Type === this.serverConstants.conversationStimuliTypeConstants.image
                        && stimulus.IsBroken !== true) {
                        this.fileStorageService.checkStimulusUrl(stimulus.ThumbnailUrl, (broken) => {
                            stimulus.IsBroken = broken;
                        });
                    }
                });
            }
            if (this.stimuli && this.stimuli.length !== 0) {
                this.stimuli.forEach((stimulus) => {
                    if (stimulus.type === this.serverConstants.conversationStimuliTypeConstants.image
                        && stimulus.IsBroken !== true) {
                        this.fileStorageService.checkStimulusUrl(stimulus.thumbnailUrl, (broken) => {
                            stimulus.IsBroken = broken;
                        });
                    }
                });
            }
        }
        if (this.item.ProbeQuestionsForEditor && this.item.ProbeQuestionsForEditor.length !== 0) {
            this.item.ProbeQuestionsForEditor.forEach((q) => {
                if (q.answer && q.answer.stimuli && q.answer.stimuli.length !== 0) {
                    q.answer.stimuli.forEach((stimulus) => {
                        if (stimulus.type === this.serverConstants.conversationStimuliTypeConstants.image
                            && stimulus.IsBroken !== true) {
                            this.fileStorageService.checkStimulusUrl(stimulus.thumbnailUrl, (broken) => {
                                stimulus.IsBroken = broken;
                            });
                        }
                    });
                }
            });
        }
    }
    onDraftFileChanges(param) {
        if (param && param.uid) {
            if (param.added) {
            }
            if (param.removed) {
            }
        }
        return true;
    }
    initAnsweredVsUnansweredQuestions() {
        if (!this.allProbeQuestions && this.item.ProbeQuestionsForEditor && this.item.ProbeQuestionsForEditor.length !== 0) {
            this.allProbeQuestions = angular.copy(this.item.ProbeQuestionsForEditor);
            const answeredQuestions = this.item.ProbeQuestionsForEditor.filter((q) => q.answer.guid);
            this.item.ProbeQuestionsForEditor = answeredQuestions.length ? answeredQuestions : [this.item.ProbeQuestionsForEditor[0]];
            this.item.RemainingQuestionsForCurrentSquareParticipant = this.allProbeQuestions.length - answeredQuestions.length;
        }
    }
    shouldUpdateDraftBeforeSaving(draft) {
        const questions = draft.questions;
        const lastQuestion = questions[questions.length - 1];
        return this.isAnswerEmpty(lastQuestion.answer, lastQuestion.questionType);
    }
    shouldUpdateOnInit() {
        var _a;
        const draft = this.getDraftObject();
        return ((_a = draft === null || draft === void 0 ? void 0 : draft.questions) === null || _a === void 0 ? void 0 : _a.length) > 1;
    }
    $onDestroy() {
        this.isComponentDestroyed = true;
        let draftObject = this.getDraftObject();
        if (!draftObject) {
            return;
        }
        if (this.shouldUpdateDraftBeforeSaving(draftObject)) {
            const questions = draftObject.questions;
            let lastQuestion = questions[questions.length - 1];
            while (this.isAnswerEmpty(lastQuestion.answer, lastQuestion.questionType)) {
                questions.pop();
                if (questions.length === 0) {
                    break;
                }
                lastQuestion = questions[questions.length - 1];
            }
            draftObject = Object.assign(Object.assign({}, draftObject), { questions });
        }
        IscDraftService.storeDraft(this.getDraftIdentifier(), draftObject);
    }
    initProbeQuestionProperties() {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.item.ProbeQuestions) {
                this.probequestionPlaceholderLabel = yield this.constantsfactory.getLabelValue('QualConversationEnterAnswerPlaceholderText');
                this.showMoreText = yield this.constantsfactory.getLabelValue('ContentHiderShowMore');
                this.showLessText = yield this.constantsfactory.getLabelValue('ContentHiderShowLess');
                this.item.ProbeQuestionsForEditor = this.isDiary
                    ? this.mappingService.mapProbeQuestionsForProbeQuestionEditor(this.item.ProbeQuestions)
                    : this.item.ProbeQuestionsForEditor || this.mappingService.mapProbeQuestionsForProbeQuestionEditor(this.item.ProbeQuestions);
            }
        });
    }
    getRoleLabel(roleType) {
        return Utils.getRoleLabel(this.serverConstants.roleConstants, roleType);
    }
    getStatusLabel(statusType, suspended) {
        return Utils.getStatusLabel(this.serverConstants.registrationStatusConstants, statusType, suspended);
    }
    enterReply(item) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!item.InDraftReply) {
                if (item.Level === 0) {
                    this.message = this.prePopulateDescription;
                }
                if (item.Level === this.serverConstants.squareConstants.maxReplyLevel) {
                    this.message = this.message || `<span class="mention" data-username="${item.Username}">@${item.Username}</span>&nbsp`;
                }
            }
            this.saveButtonClicked = false;
            this.replyActive = true;
            this.isEditMode = false;
            yield this.setSaveLabel();
            this.conversationEventsService.discussionChangeStarted(this.activityGuid);
        });
    }
    cancelReply() {
        return __awaiter(this, void 0, void 0, function* () {
            this.buttonClicked = true;
            this.replyActive = false;
            this.message = '';
            this.caption = '';
            this.stimuli.splice(0);
            this.attachments.splice(0);
            this.buttonClicked = false;
            this.isEditMode = false;
            this.conversationEventsService.discussionChangeEnded(this.activityGuid);
            IscDraftService.broadcastDraftRemove(this.$rootScope, this.getDraftIdentifier());
        });
    }
    get isEditable() {
        return this.item &&
            this.item.IsEditable &&
            this.item.IsModeratorPost;
    }
    get isProbeQuestionEditable() {
        return this.replyActive ||
            (this.item && this.item.ProbeQuestions && this.item.ProbeQuestions.length > 0
                && this.item.ProbeQuestions.some((question) => !question.Answer || !question.Answer.Guid));
    }
    editPostClick() {
        return __awaiter(this, void 0, void 0, function* () {
            const isTopicWithQuestions = this.item.Type === this.serverConstants.conversationElementTypeConstants.topic
                && (!!this.item.ProbeQuestions && this.item.ProbeQuestions.length !== 0);
            if (isTopicWithQuestions) {
                return yield this.redirectToWizard();
            }
            if (this.item.Type === this.serverConstants.conversationElementTypeConstants.individualUpdatePost
                || this.item.Type === this.serverConstants.conversationElementTypeConstants.topic) {
                return yield this.redirectToConversation();
            }
            this.message = this.item.Message;
            this.caption = this.item.Caption;
            this.stimuli = this.mappingService.mapConversationStimuliForConversationEditor(this.item.Stimuli);
            this.attachments = this.mappingService.mapConversationAttachmentsForConversationEditor(this.item.Attachments);
            this.isEditMode = true;
            this.replyActive = false;
            yield this.setSaveLabel();
            this.conversationEventsService.discussionChangeStarted(this.activityGuid);
        });
    }
    redirectToWizard() {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getReassuranceViaModal();
            if (response) {
                const url = this.authService.getWizardUrl(this.activityGuid, 'Build');
                window.open(url, '_blank');
            }
        });
    }
    redirectToConversation() {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getReassuranceViaModal();
            if (response) {
                const url = this.authService.getActivityConversationUrl(this.activityGuid, this.item.Guid);
                window.open(url, '_blank');
            }
        });
    }
    getReassuranceViaModal() {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.$mdDialog.show({
                controller: QualActivityPostModalController,
                controllerAs: 'vm',
                template: editModal,
                parent: angular.element(document.body),
                clickOutsideToClose: true,
                locals: {
                    cancel: () => this.$mdDialog.hide(false),
                    ok: () => this.$mdDialog.hide(true),
                    canRedirectToWizard: !this.mediaservice.isMobileApp,
                },
            });
        });
    }
    get isOwnPost() {
        return this.item.SquareParticipantGuid === this.participantFactory.Guid;
    }
    getSaveButtonText() {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.isEditMode) {
                return yield this.constantsfactory.getLabelValue('LabelForumPostSave');
            }
            if (this.item.IsOneByOne) {
                return this.item.RemainingQuestionsForCurrentSquareParticipant > 1 ?
                    yield this.constantsfactory.getLabelValue('LabelForumReply') : yield this.constantsfactory.getLabelValue('LabelQualSendAllProbeAnswers');
            }
            if (this.item.ProbeQuestions && !this.replyActive) {
                return yield this.constantsfactory.getLabelValue('LabelQualSendAllProbeAnswers');
            }
            return this.item.IsModeratorPost && this.item.Level === 0 ?
                yield this.constantsfactory.getLabelValue('LabelForumSendReply') : yield this.constantsfactory.getLabelValue('LabelForumReply');
        });
    }
    reportAsInappropriate() {
        this.conversationService.reportAsInappropriate();
    }
    sendReply() {
        return __awaiter(this, void 0, void 0, function* () {
            this.saveButtonClicked = true;
            this.buttonClicked = true;
            this.replyActive = false;
            this.startPublishingTask();
            this.channel = !isNaN(parseInt(this.channel, 10)) ? parseInt(this.channel, 10) : 0;
            this.endPublishingTask();
            const clonedItem = _.cloneDeep(this.item);
            clonedItem.ParentMessage = !this.isEditMode ? this.item.Guid : this.item.ParentMessage;
            const replyGuid = yield this.conversationService.sendQualReply(clonedItem, this.message, this.stimuli, this.attachments, this.newAttachmentFiles, this.channel, this.caption);
            if (this.stimuli &&
                this.stimuli.length &&
                this.stimuli.find((s) => s.type === this.serverConstants.conversationStimuliTypeConstants.video && !s.thumbnailUrl)) {
                const videos = this.stimuli.filter((s) => s.type === this.serverConstants.conversationStimuliTypeConstants.video);
                const stimulusProgressList = this.mappingService.mapConversationStimuliForStimulusProgressList(videos);
                this.conversationService.createAndShowVideoUploadTask(replyGuid, stimulusProgressList);
            }
            this.cancelReply();
            if (!this.isComponentDestroyed && replyGuid) {
                const isEdit = clonedItem.Guid && replyGuid === clonedItem.Guid;
                if (isEdit) {
                    this.$state.reload();
                    return;
                }
                this.$stateParams.replyGuid = replyGuid;
                this.expandFirstParentPost();
                yield this.reloadConversation(false);
            }
            this.buttonClicked = false;
            this.conversationEventsService.discussionChangeEnded(this.activityGuid);
            this.isEditMode = false;
        });
    }
    onNewContributionClick() {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.reloadConversation(true);
        });
    }
    onHeartClick() {
        return __awaiter(this, void 0, void 0, function* () {
            this.buttonClicked = true;
            yield this.conversationService.onQualHeartClick(this.item);
            this.buttonClicked = false;
        });
    }
    mentionSelect(item) {
        if (item) {
            const escapedLabel = _.escape(item.label);
            return `<span data-username="${escapedLabel}">@${escapedLabel}</span>&nbsp;`;
        }
        return '';
    }
    mentionSearch(term) {
        return __awaiter(this, void 0, void 0, function* () {
            if (term) {
                return yield this.forumService.getMentionUsersForConversation(this.activityGuid, term, this.item.Guid);
            }
            return this.$q(() => undefined);
        });
    }
    hashtagSelect(item) {
        if (item) {
            return `<span>${item.text}</span>&nbsp;`;
        }
        return '';
    }
    hashtagSearch(term) {
        return __awaiter(this, void 0, void 0, function* () {
            if (term) {
                return yield this.forumService.getHashtagsForConversation(this.activityGuid, term);
            }
            return this.$q(() => undefined);
        });
    }
    get avatar() {
        return this.conversationService.getAvatar(this.item);
    }
    isVisualFocusedOpeningPost() {
        return this.conversationFocusType === this.serverConstants.conversationFocusTypeConstants.visual && this.isOpeningPost;
    }
    isStimuliDownloadSupported(stimuli) {
        return stimuli.Type === this.serverConstants.conversationStimuliTypeConstants.image ||
            stimuli.Type === this.serverConstants.conversationStimuliTypeConstants.video;
    }
    isNullOrEmpty(checkedString) {
        return checkedString === null || checkedString === undefined || checkedString === '';
    }
    get showContributionTitle() {
        return this.isDiary && !this.isOpeningPost && this.item.Level > 0;
    }
    saveAllProbeAnswers() {
        return __awaiter(this, void 0, void 0, function* () {
            this.buttonClicked = true;
            this.saveButtonClicked = true;
            this.replyContentWhileSavingDisabled = true;
            const draftId = this.getDraftIdentifier();
            this.startPublishingTask();
            const probeAnswers = this.item.ProbeQuestionsForEditor.map((question) => question.answer);
            try {
                this.endPublishingTask();
                const replyGuid = yield this.conversationService.replyToProbingQuestions(probeAnswers, this.activityType, this.conversationFocusType, this.contributionType, this.channel);
                const answersStimuli = [].concat(...probeAnswers.map((answer) => answer.stimuli));
                if (answersStimuli &&
                    answersStimuli.length &&
                    answersStimuli.find((s) => s.type === this.serverConstants.conversationStimuliTypeConstants.video && !s.thumbnailUrl)) {
                    const videos = answersStimuli.filter((s) => s.type === this.serverConstants.conversationStimuliTypeConstants.video);
                    const stimulusProgressList = this.mappingService.mapConversationStimuliForStimulusProgressList(videos);
                    this.conversationService.createAndShowVideoUploadTask(replyGuid, stimulusProgressList);
                }
                if (replyGuid !== null) {
                    IscDraftService.broadcastDraftRemove(this.$rootScope, draftId);
                    this.$stateParams.replyGuid = replyGuid;
                    this.expandFirstParentPost();
                    yield this.reloadConversation(false);
                    this.allQuestionsAnswered = true;
                    this.isEditMode = false;
                    this.replyActive = false;
                }
                if (this.isSequencedActivity) {
                    const request = {
                        finishedActivityGuid: this.activityGuid,
                        finishedSampleGuid: undefined,
                    };
                    if (this.isOpeningPost) {
                        yield this.activitySequenceHelperService.onSequencedActivityCompleted(request);
                    }
                }
            }
            catch (_a) {
            }
            this.buttonClicked = false;
            this.replyContentWhileSavingDisabled = false;
        });
    }
    expandFirstParentPost() {
        if (this.$stateParams.expandedPosts) {
            if (!this.$stateParams.expandedPosts.includes(this.item.FirstParentGuid)) {
                this.$stateParams.expandedPosts.push(this.item.FirstParentGuid);
            }
        }
        else {
            this.$stateParams.expandedPosts = [this.item.FirstParentGuid];
        }
    }
    get showConversationEditor() {
        return (!this.item.ProbeQuestions && this.isEditMode)
            || this.replyActive
                && (!this.item.ProbeQuestions || this.item.IsReadonly);
    }
    shouldHighLightPost() {
        return !this.item.IsReadonly && this.item.Type === this.serverConstants.conversationElementTypeConstants.individualUpdatePost;
    }
    handleProbeQuestionsAnswerSave(draftOnly) {
        if (this.item.IsOneByOne) {
            if (this.item.ProbeQuestionsForEditor.length < this.allProbeQuestions.length) {
                this.item.RemainingQuestionsForCurrentSquareParticipant = this.allProbeQuestions.length - this.item.ProbeQuestionsForEditor.length;
                this.item.ProbeQuestionsForEditor.push(this.allProbeQuestions[this.item.ProbeQuestionsForEditor.length]);
                this.setSaveLabel();
            }
            else {
                if (!draftOnly) {
                    this.saveAllProbeAnswers();
                }
            }
        }
        else {
            if (!draftOnly) {
                this.saveAllProbeAnswers();
            }
        }
    }
    setSaveLabel() {
        return __awaiter(this, void 0, void 0, function* () {
            this.saveButtonText = yield this.getSaveButtonText();
            this.conversationEditorConfig.saveLabel = this.saveButtonText;
            this.editVisualFocusedConversationConfig = this.saveButtonText;
        });
    }
    startPublishingTask() {
        if (this.publishingTask == null) {
            const { publishingTask } = this.conversationService.makeTasks();
            this.publishingTask = publishingTask;
            this.publishingTask.show();
        }
    }
    endPublishingTask() {
        this.publishingTask.hide();
        this.publishingTask = null;
    }
    getStimulusThumbnail(previewStimulus) {
        let result = null;
        if (previewStimulus && previewStimulus.guid) {
            _.forEach(this.item.Stimuli, (stimulus) => {
                if (!result && stimulus.Guid === previewStimulus.guid) {
                    result = {
                        thumbnailUrl: stimulus.ThumbnailUrl,
                        url: stimulus.Type === this.serverConstants.conversationStimuliTypeConstants.image ? stimulus.Value : stimulus.Url,
                    };
                }
            });
            if (!result && this.item.ProbeQuestions) {
                _.forEach(this.item.ProbeQuestions, (probeQuestion) => {
                    if (probeQuestion.Stimuli && probeQuestion.Stimuli.length) {
                        _.forEach(probeQuestion.Stimuli, (stimulus) => {
                            if (!result && stimulus.Guid === previewStimulus.guid) {
                                result = {
                                    thumbnailUrl: stimulus.ThumbnailUrl,
                                    url: stimulus.Type === this.serverConstants.conversationStimuliTypeConstants.image ? stimulus.Value : stimulus.Url,
                                };
                            }
                        });
                    }
                    if (!result && probeQuestion.Answer && probeQuestion.Answer.Stimuli && probeQuestion.Answer.Stimuli.length) {
                        _.forEach(probeQuestion.Answer.Stimuli, (stimulus) => {
                            if (!result && stimulus.Guid === previewStimulus.guid) {
                                result = {
                                    thumbnailUrl: stimulus.ThumbnailUrl,
                                    url: stimulus.Type === this.serverConstants.conversationStimuliTypeConstants.image ? stimulus.Value : stimulus.Url,
                                };
                            }
                        });
                    }
                });
            }
            if (!result && this.item.ProbeQuestionsForEditor) {
                _.forEach(this.item.ProbeQuestionsForEditor, (probeQuestion) => {
                    if (!result && probeQuestion.answer && probeQuestion.answer.stimuli && probeQuestion.answer.stimuli.length) {
                        _.forEach(probeQuestion.answer.stimuli, (stimulus) => {
                            if (!result && stimulus.guid === previewStimulus.guid) {
                                result = {
                                    thumbnailUrl: stimulus.thumbnailUrl,
                                    url: stimulus.type === this.serverConstants.conversationStimuliTypeConstants.image ? stimulus.value : stimulus.url,
                                };
                            }
                        });
                    }
                });
            }
        }
        return result;
    }
    getUploadProgress(stimulus) {
        var _a;
        if (!stimulus) {
            return;
        }
        let result = null;
        let stimulusUploadProgress;
        const stimulusObject = this.isInstanceOfIStimulus(stimulus)
            ? this.mappingService.mapConversationStimuliForConversationEditor([stimulus])[0]
            : stimulus;
        if (stimulusObject.value
            && stimulusObject.type === this.serverConstants.conversationStimuliTypeConstants.video
            && !stimulusObject.thumbnailUrl) {
            this.getExistingStimulusData(stimulus);
            let stimulusValue = stimulusObject.value;
            if (this.squareFactory.VideoStorage === this.serverConstants.clientVideoStorageConstants.human8MediaService) {
                stimulusValue = (_a = stimulusObject.guid) !== null && _a !== void 0 ? _a : stimulusObject.value;
            }
            stimulusUploadProgress = {
                stimulusValue,
                progress: this.squareFactory.VideoStorage === this.serverConstants.clientVideoStorageConstants.mux
                    ? this.muxService.getStimulusUploadProgress(stimulusObject.value)
                    : this.fileStorageService.getStimulusUploadProgress(stimulusObject.guid),
            };
            this.conversationService.updateStimuliUploadTaskProgress(this.item.Guid, stimulusUploadProgress);
            result = stimulusUploadProgress.progress;
        }
        if (stimulusObject.guid
            && stimulusObject.type === this.serverConstants.conversationStimuliTypeConstants.image
            && !stimulusObject.thumbnailUrl) {
            this.getExistingStimulusData(stimulus);
            stimulusUploadProgress = {
                stimulusValue: stimulusObject.guid,
                progress: this.fileStorageService.getStimulusUploadProgress(stimulusObject.guid),
            };
            this.conversationService.updateStimuliUploadTaskProgress(this.item.Guid, stimulusUploadProgress);
            result = stimulusUploadProgress.progress;
        }
        return result;
    }
    getExistingStimulusData(currentStimulus) {
        if (currentStimulus.value && currentStimulus.value !== '') {
            _.forEach(this.item.Stimuli, (stimulus) => {
                if (stimulus.Value === currentStimulus.value) {
                    currentStimulus.thumbnailUrl = stimulus.ThumbnailUrl;
                    currentStimulus.url = stimulus.Url;
                    return false;
                }
            });
            if (this.item.ProbeQuestions) {
                _.forEach(this.item.ProbeQuestions, (probeQuestion) => {
                    if (probeQuestion.Stimuli && probeQuestion.Stimuli.length) {
                        _.forEach(probeQuestion.Stimuli, (stimulus) => {
                            if (stimulus.Value === currentStimulus.value) {
                                currentStimulus.thumbnailUrl = stimulus.ThumbnailUrl;
                                currentStimulus.url = stimulus.Url;
                                return false;
                            }
                        });
                    }
                    if (probeQuestion.Answer && probeQuestion.Answer.Stimuli && probeQuestion.Answer.Stimuli.length) {
                        _.forEach(probeQuestion.Answer.Stimuli, (stimulus) => {
                            if (stimulus.Value === currentStimulus.value) {
                                currentStimulus.thumbnailUrl = stimulus.ThumbnailUrl;
                                currentStimulus.url = stimulus.Url;
                                return false;
                            }
                        });
                    }
                });
            }
        }
    }
    isInstanceOfIStimulus(object) {
        return 'Id' in object && 'Guid' in object;
    }
    getAuthorPublicProfileUrl() {
        let result = null;
        if (this.squareFactory.IsPublicProfileEnabled
            && this.item.SquareParticipantRole === this.serverConstants.roleConstants.participant
            && this.item.SquareParticipantStatus !== this.serverConstants.registrationStatusConstants.unsubscribed
            && this.item.SquareParticipantStatus !== this.serverConstants.registrationStatusConstants.anonymized
            && this.item.Suspended !== true) {
            result = this.$state.href('container.main.publicProfile', { username: this.item.Username });
        }
        return result;
    }
    getDraftQuestions() {
        return this.item.ProbeQuestions
            && this.item.ProbeQuestions.length > 0
            && this.item.ProbeQuestionsForEditor
            && (this.item.ProbeQuestionsForEditor.length === this.item.ProbeQuestions.length
                || (this.item.IsOneByOne === true && this.item.ProbeQuestionsForEditor.length > 0))
            && (_.find(this.item.ProbeQuestions, (probeQuestion) => probeQuestion.HasBeenAnswered === false
                && (!probeQuestion.Answer || probeQuestion.Answer.Guid === undefined)) !== undefined || this.isDiary)
            ? this.item.ProbeQuestionsForEditor
            : undefined;
    }
    synchronizeDraftQuestionsAndAnswers(draft, oneByOne) {
        draft.questions.forEach((question) => {
            const existing = this.item.ProbeQuestionsForEditor.find((eq) => eq.guid === question.guid);
            if (existing) {
                existing.answer = question.answer;
                if (existing.answer) {
                    existing.answer.stimuli = [];
                    existing.answer.attachments = [];
                    existing.answer.newAttachmentFiles = [];
                    existing.answer.newStimulusFiles = [];
                    if (oneByOne) {
                        this.handleProbeQuestionsAnswerSave(true);
                    }
                }
            }
        });
    }
    setDraftObject(value) {
        if (this.item.HasBeenAnswered) {
            return;
        }
        const genericDraft = value;
        this.message = genericDraft.message;
        this.replyActive = genericDraft.questions === undefined;
        if (genericDraft.questions) {
            if (this.item.IsOneByOne === true) {
                this.initAnsweredVsUnansweredQuestions();
            }
            this.synchronizeDraftQuestionsAndAnswers(genericDraft, this.item.IsOneByOne === true);
        }
    }
    getDraftObject() {
        let result;
        const questionsData = this.getDraftQuestions();
        if (questionsData) {
            questionsData.forEach((q) => {
                if (q.answer && q.answer.stimuli && q.answer.stimuli.length !== 0) {
                    q.answer.stimuli.forEach((stimulus) => {
                        if (stimulus.videoPlayer) {
                            stimulus.videoPlayer = undefined;
                        }
                    });
                }
            });
        }
        if (questionsData || this.replyActive) {
            const genericDraft = {
                attachments: [],
                message: this.message,
                stimuli: [],
                questions: questionsData,
            };
            result = genericDraft;
        }
        return result;
    }
    getDraftIdentifier() {
        return `${this.item.Guid}_${this.participantFactory.Guid}`;
    }
    isAnswerEmpty(answer, questionType) {
        const isMessageEmpty = answer.message === undefined || answer.message.length === 0;
        const isStimuliEmpty = answer.stimuli.length === 0;
        const areAttachmentsEmpty = answer.attachments.length === 0;
        switch (questionType) {
            case this.serverConstants.conversationQuestionTypeConstants.text:
                return isMessageEmpty;
            case this.serverConstants.conversationQuestionTypeConstants.image:
            case this.serverConstants.conversationQuestionTypeConstants.video:
            case this.serverConstants.conversationQuestionTypeConstants.imageAndVideo:
                return isStimuliEmpty;
            case this.serverConstants.conversationQuestionTypeConstants.attachment:
                return areAttachmentsEmpty;
            case this.serverConstants.conversationQuestionTypeConstants.freestyle:
                return isMessageEmpty && isStimuliEmpty && areAttachmentsEmpty;
        }
    }
    autosave() {
        let draftObject = this.getDraftObject();
        if (this.item.IsOneByOne && draftObject) {
            const shouldUpdateDraft = this.shouldUpdateDraftBeforeSaving(draftObject);
            const questions = draftObject.questions;
            if (shouldUpdateDraft && questions.length === 1) {
                IscDraftService.broadcastDraftRemove(this.$rootScope, this.getDraftIdentifier());
                return;
            }
            let lastNotEmptyAnswerIndex = 0;
            for (let i = questions.length - 1; i >= 0; i--) {
                if (!this.isAnswerEmpty(questions[i].answer, questions[i].questionType)) {
                    lastNotEmptyAnswerIndex = i;
                    break;
                }
            }
            for (let i = lastNotEmptyAnswerIndex + 1; i < questions.length - 1; i++) {
                questions.pop();
            }
            draftObject = Object.assign({ questions }, draftObject);
        }
        IscDraftService.broadcastDraftUpdate(this.$rootScope, this.getDraftIdentifier(), draftObject);
    }
    retryStimulus(newStimulus) {
        if (newStimulus && newStimulus.guid) {
            let foundStimulus = null;
            let foundMappedStimulus = null;
            if (this.item.ProbeQuestions) {
                _.forEach(this.item.ProbeQuestions, (probeQuestion) => {
                    if (probeQuestion.Answer && probeQuestion.Answer.Stimuli && probeQuestion.Answer.Stimuli.length) {
                        _.forEach(probeQuestion.Answer.Stimuli, (stimulus) => {
                            if (stimulus.Guid === newStimulus.guid) {
                                foundStimulus = stimulus;
                                return false;
                            }
                        });
                    }
                });
                if (this.item.ProbeQuestionsForEditor) {
                    _.forEach(this.item.ProbeQuestionsForEditor, (probeQuestion) => {
                        if (probeQuestion.answer && probeQuestion.answer.stimuli && probeQuestion.answer.stimuli.length) {
                            _.forEach(probeQuestion.answer.stimuli, (stimulus) => {
                                if (stimulus.guid === newStimulus.guid) {
                                    foundMappedStimulus = stimulus;
                                    return false;
                                }
                            });
                        }
                    });
                }
            }
            if (!foundStimulus && this.item.Stimuli && this.item.Stimuli.length) {
                _.forEach(this.item.Stimuli, (stimulus) => {
                    if (stimulus.Guid === newStimulus.guid) {
                        foundStimulus = stimulus;
                        return false;
                    }
                });
            }
            if (!foundMappedStimulus && this.postStimuli && this.postStimuli.length) {
                _.forEach(this.postStimuli, (stimulus) => {
                    if (stimulus.guid === newStimulus.guid) {
                        foundMappedStimulus = stimulus;
                        return false;
                    }
                });
            }
            if (foundStimulus && foundStimulus.IsBroken === true && foundStimulus.IsBrokenAndBelongsToCurrentUser === true) {
                const stimuli = [];
                stimuli.push(newStimulus);
                this.conversationService.prepareStimuliAndNewStimuli(stimuli)
                    .then((result) => {
                    if (result && result.stimuli && result.stimuli.length > 0) {
                        result.stimuli.forEach((stimulus) => {
                            if (stimulus.fileContentToBeRemoved) {
                                stimulus.file = undefined;
                                this.conversationService.updateStimulusAfterNewUpload(stimulus);
                            }
                        });
                        foundStimulus.IsBroken = undefined;
                        if (foundStimulus.Type === this.serverConstants.conversationStimuliTypeConstants.video) {
                            foundStimulus.Value = result.stimuli[0].id;
                        }
                        else if (foundStimulus.Type === this.serverConstants.conversationStimuliTypeConstants.image) {
                            foundStimulus.Url = result.stimuli[0].url;
                            foundStimulus.ThumbnailUrl = result.stimuli[0].thumbnailUrl;
                        }
                        if (foundMappedStimulus) {
                            foundMappedStimulus.IsBroken = undefined;
                            if (foundMappedStimulus.type === this.serverConstants.conversationStimuliTypeConstants.video) {
                                foundMappedStimulus.value = result.stimuli[0].id;
                            }
                            else if (foundMappedStimulus.type === this.serverConstants.conversationStimuliTypeConstants.image) {
                                foundMappedStimulus.url = result.stimuli[0].url;
                                foundMappedStimulus.thumbnailUrl = result.stimuli[0].thumbnailUrl;
                            }
                        }
                    }
                });
            }
        }
    }
}
QualActivityPostController.$inject = [
    '$stateParams',
    '$state',
    '$rootScope',
    'forumService',
    'conversationService',
    'serverConstants',
    'conversationEventsService',
    'featureToggleService',
    'constantsfactory',
    'participantFactory',
    'squareFactory',
    'authService',
    '$mdDialog',
    'mediaservice',
    'mappingService',
    'muxService',
    'fileStorageService',
    'iscStimuliTypeService',
    '$q',
    'activitySequenceHelperService',
];
