import { useSquareStore } from '@/store/square-store';
import { RouteRecordRaw } from 'vue-router';
import { RouteNames } from './route-names.enum';

const ActivationPage = () => import('@/pages/activation-page.vue');

export const participantActivationRoute: RouteRecordRaw = {
  name: RouteNames.ParticipantActivation,
  path: '/activation',
  component: ActivationPage,
  meta: {
    title: '',
    requireLogin: false,
    hideFooter: true,
  },
  beforeEnter: (to) => {
    // if we do not have the AD login enabled for this square, go to root
    if (!useSquareStore().info.adLogin || !to.query.identity) {
      return {
        name: RouteNames.Main,
      };
    }
    return true;
  },
};
