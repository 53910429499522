import { useAuthenticationStore } from '@/store/authentication-store';
import { useSquareStore } from '@/store/square-store';
import { LocationQueryRaw, RouteLocationNormalized } from 'vue-router';
import { NavigationGuardReturn } from '../router.types';
import { RouteNames } from '../routes/route-names.enum';
import { Role } from '@/common/constants/enums';
import { isSupportedMobileDeviceOS } from './force-mobile-app-guard';
import { setShouldActivateAD } from '@/common/utils/sso';
import { registerEmailAddressForAdActivation } from '@api/services/command/default/ParticipantService';
import { squareParticipantDetails } from '@api/services/query/default/ParticipantService';
import { SocialLoginType } from '@api/models/command';
import { isOpenRegistrationScreenerActiveForSquare } from '@api/services/query/default/ActivityService';
import { SquareInfoDetailItem } from '@api/models/query';

export default async (to: RouteLocationNormalized): Promise<NavigationGuardReturn> => {
  const authStore = useAuthenticationStore();
  const squareStore = useSquareStore();
  const isLoginRequiredForRoute = to.meta?.requireLogin;
  if (isLoginRequiredForRoute && !authStore.isAuthorized /* Only redirect to login page if we aren't authorized yet */) {
    // Add a from query parameter to be redirected to after login
    // We don't do this for the home page since that's the default redirect if the from query parameter isn't filled in.
    const query : LocationQueryRaw = to.name !== RouteNames.Home ? { from: to.path } : { };
    if (squareStore.info.adLogin) {
      if (to.query.token) {
        // we need to set the token to the sso login page, otherwise we`re in a loop
        query.token = to.query.token.toString();
      }
    }
    // for AD login:
    // - if mobile, return default route, SSO
    // - otherwise, if square info says show verify its you, return AD registration route (verify its you)
    //              if its disabled, return default route, SSO
    const routeName = squareStore.info.adLogin
      ? (isSupportedMobileDeviceOS()
        ? RouteNames.SSO
        : await getAdLoginRoute(squareStore.info))
      : RouteNames.Login;

    if (routeName === RouteNames.ADRegistration
        || to.name === RouteNames.Registration) {
      const adRegistrationResult = await handleAdRegistrationRoute(
        to.redirectedFrom?.params?.sqPartId?.toString() || '', query);
      return adRegistrationResult;
    }
    return {
      name: routeName,
      query,
    };
  }
  return handleAdSquareWithoutAdLogin(to);
};

const getAdLoginRoute = async (info: SquareInfoDetailItem) : Promise<string> => {
  const openRegistration = info.adLandingPage
    || (info.isOpenRegistration && await isOpenRegistrationScreenerActiveForSquare());
  return openRegistration
    ? RouteNames.ADOpenRegistration
    : info.adVerification
      ? RouteNames.ADRegistration
      : RouteNames.SSO;
};

const handleAdSquareWithoutAdLogin = (to: RouteLocationNormalized) : boolean => {
  const squareStore = useSquareStore();
  const authStore = useAuthenticationStore();
  if (squareStore.info.adLogin
    && !authStore.isAdUser
    && authStore.isAuthorized
    && (authStore.userRole === Role.Participant
      || authStore.userRole === Role.ClientAdmin
      || authStore.userRole === Role.ClientEditor
      || authStore.userRole === Role.ProfessionalAdmin
      || authStore.userRole === Role.Observer)
    && to.name !== RouteNames.ParticipantActivation
    && to.name !== RouteNames.SSO) {
    // we have the square AD enabled but this authorized member is not in AD already
    // we will silently logout and present the activation
    const squareParticipantGuid = authStore.tokenInfo?.squareParticipantGuid || '';

    // asynchronous, but should not impact further
    authStore.signout(false, false, true);

    setShouldActivateAD();
    goToActivation(squareParticipantGuid);
    return false;
  }
  return true;
};

const handleAdRegistrationRoute = async (
  squareParticipantGuid: string, query: LocationQueryRaw) => {
  // if the participant clicks on a registration link (he got it before the square got migrated) he will not go to verify its you window
  // since we know his email.
  // Here we will call the needed method for adRegistration without going to verify it's you
  if(squareParticipantGuid) {
    const details = await squareParticipantDetails(squareParticipantGuid);
    const result = await registerEmailAddressForAdActivation(false, details?.detail?.email);
    // If al went well we now go to the sso and this will bring us to the sign up page
    if (result?.success === true) {
      const authStore = useAuthenticationStore();
      authStore.signout(false, false, true);
      if (result.migrated === true) {
        query.isVerified = 1;
        if (result.socialLoginType === SocialLoginType.None) {
          query.email = result.adEmail;
        } else {
          query.type = result.socialLoginType;
        }
        return {
          name: RouteNames.SSO,
          query,
        };
      }
      setShouldActivateAD();
      goToActivation(squareParticipantGuid);
      // return false so that router does not load a different route
      return false;
    }
  }

  // if something goes wrong with the older registration reroute to verify its you since he then can get a new email
  return {
    name: RouteNames.ADRegistration,
    query,
  };
};

const goToActivation = (squareParticipantGuid: string) => {
  const url = new URL(window.location.origin);
  url.pathname = 'activation';
  url.searchParams.append('identity', squareParticipantGuid);
  window.location.href = url.toString();
};
