import { useAuthenticationStore } from '@/store/authentication-store';
import { useSquareStore } from '@/store/square-store';
import { RouteLocationNormalized } from 'vue-router';
import { NavigationGuardReturn } from '../router.types';
import { getSquareAdActivationLinkFromUrl } from '@api/services/query/default/SquareService';
import { setShouldActivateAD } from '@/common/utils/sso';

export default async (to: RouteLocationNormalized): Promise<NavigationGuardReturn> => {

  // this guard will check to see if the route can handle AD activation
  // if route has the meta 'adSquareHandle' set, then it means that the route
  // can identify unique square participant based on its URL
  // if meta set, we will check for square participant identification and, if we find,
  // we will return the activation URL with its identity
  const authStore = useAuthenticationStore();
  const squareStore = useSquareStore();

  // only use the guard if AD login is enabled for this square
  if (squareStore.info.adLogin) {
    const handleAdSquare = to.meta?.adSquareHandle;

    // if route is marked as to handle AD square, we need to know
    // if we should identify the member and go to the AD activation form
    if (handleAdSquare === true) {
      const shouldCheckUrl = !authStore.isAuthorized || !authStore.isAdUser;

      if (shouldCheckUrl) {
        // this means that we need to identify the member based on the route
        const toUrl = new URL(to.fullPath, window.location.origin);
        const uri = await getSquareAdActivationLinkFromUrl(decodeURIComponent(toUrl.toString()));
        if (uri) {
          setShouldActivateAD();
          window.location.href = uri;
          return false;
        }
      }
    }
  }
};
