import { RouteRecordRaw } from 'vue-router';
import { RouteNames } from './route-names.enum';
import { useAuthenticationStore } from '@/store/authentication-store';

const AdRegistrationPage = () => import('@/pages/ad-registration-page.vue');

export const adRegistrationRoute: RouteRecordRaw = {
  name: RouteNames.ADRegistration,
  path: '/adregistration',
  component: AdRegistrationPage,
  meta: {
    title: '',
    requireLogin: false,
    hideFooter: true,
  },
  beforeEnter: () => {
    // If already logged in and navigating to verify its you page, redirect to home page
    if (useAuthenticationStore().isAuthorized) {
      return { name: RouteNames.Home };
    }
  },
};
