import { RouteLocationNormalized } from 'vue-router';
import { NavigationGuardReturn } from '../router.types';
import { RouteNames } from '../routes/route-names.enum';
import { usePagesStore } from '@/store/pages-store';

export default async (to: RouteLocationNormalized): Promise<NavigationGuardReturn> => {
  if (!to.params?.pageGuid) {
    return;
  }

  // If no login is required, we don't need to check anything
  if(!to.meta?.requireLogin){
    return;
  }

  const hasAccess = await usePagesStore().allowedToNavigateToPage(to.params.pageGuid as string);
  if (!hasAccess) {
    return {
      name: RouteNames.LandingPage,
    };
  }

  return;
};
