import { RouteRecordRaw } from 'vue-router';
import { RouteNames } from './route-names.enum';
import { checkAzureAdB2CLogin } from '@/pages/__helpers__/form-related-pages';

const ResetPasswordComponent = () => import('@/pages/reset-password-page.vue');

export const resetPasswordRoute: RouteRecordRaw = {
  name: RouteNames.ResetPassword,
  path: '/resetpassword',
  component: ResetPasswordComponent,
  meta: {
    requireLogin: false,
    title: '(LabelWindowTitleForgotPassword)',
  },
  beforeEnter: async () => await checkAzureAdB2CLogin(),
};
