import { RouteRecordRaw } from 'vue-router';
import { RouteNames } from './route-names.enum';
import { useAuthenticationStore } from '@/store/authentication-store';
import { useSquareStore } from '@/store/square-store';
import { isOpenRegistrationScreenerActiveForSquare } from '@api/services/query/default/ActivityService';

const AdOpenRegistrationPage = () => import('@/pages/ad-openregistration-page.vue');

export const adOpenRegistrationRoute: RouteRecordRaw = {
  name: RouteNames.ADOpenRegistration,
  path: '/landing',
  component: AdOpenRegistrationPage,
  meta: {
    title: '',
    requireLogin: false,
    hideFooter: false,
  },
  beforeEnter: async () => {
    const squareStore = useSquareStore();
    // If already logged in and navigating to verify its you page, redirect to home page
    if (useAuthenticationStore().isAuthorized) {
      return { name: RouteNames.Home };
    }
    if (!squareStore.info.adLogin) {
      return { name: RouteNames.Home };
    }
    if (!squareStore.info.adLandingPage && !await isOpenRegistrationScreenerActiveForSquare()) {
      return { name: RouteNames.Home };
    }
  },
};
