import { __extends } from "tslib";
import { KeyEventSubscription } from '../IKeyEventSubscription';
var MaxLengthSubscription = (function (_super) {
    __extends(MaxLengthSubscription, _super);
    function MaxLengthSubscription($sce, richInput) {
        var _this = _super.call(this) || this;
        _this.$sce = $sce;
        _this.richInput = richInput;
        return _this;
    }
    MaxLengthSubscription.prototype.handler = function (event) {
        this.validate();
    };
    MaxLengthSubscription.prototype.validate = function () {
        if (!this.richInput.maxlength) {
            return;
        }
        this.richInput.ngModelController.$setValidity('maxlength', true);
        var valueLength = this.getValueRealLength();
        if (valueLength > this.richInput.maxlength) {
            this.richInput.ngModelController.$setValidity('maxlength', false);
        }
    };
    MaxLengthSubscription.prototype.getValueRealLength = function () {
        if (!this.richInput.ngModelController) {
            return 0;
        }
        var value = this.richInput.ngModelController.$viewValue || '';
        var stripHtmlRegex = /<[^>]*>/g;
        var trustedValue = this.$sce.getTrustedHtml(value);
        var strippedValue = trustedValue.replace(stripHtmlRegex, '');
        strippedValue = strippedValue.replace('&nbsp;', '').replace(/&#\d+;/g, '');
        return strippedValue.trim().length;
    };
    return MaxLengthSubscription;
}(KeyEventSubscription));
export { MaxLengthSubscription };
