import { RouteRecordRaw } from 'vue-router';
import { useAuthenticationStore } from '@/store/authentication-store';
import { RouteNames } from './route-names.enum';
import LoginPage from '@/pages/login-page.vue';
import { useSquareStore } from '@/store/square-store';

export const loginRoute: RouteRecordRaw = {
  name: RouteNames.Login,
  path: '/login',
  component: LoginPage,
  meta: {
    title: 'Login',
    requireLogin: false,
  },
  beforeEnter: () => {
    // If already logged in and navigating to login page, redirect to home page
    if (useAuthenticationStore().isAuthorized) {
      return { name: RouteNames.Home };
    }
    if (useSquareStore().info.adLogin) {
      return { name: RouteNames.SSO };
    }
  },
};
