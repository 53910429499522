import { RouteLocationNormalized, RouteLocationRaw } from 'vue-router';
import { InSitesSquareLogoutRedirectType, RedirectForumType, ResearcherLoginRedirectType, Role } from '@/common/constants/enums';
import router from '@/router';
import { useSquareStore } from '@/store/square-store';
import { useAuthenticationStore } from '@/store/authentication-store';
import { combineURLPaths } from '@ui/common/utils/url';

export const getSquareAdminUrlForModeratorAndObserver = (fromSwitchInterface = false, activateWarning = false): string => {
  const url = new URL(useSquareStore().adminUrl);
  url.pathname = combineURLPaths(url.pathname, 'researcherlogin');

  url.searchParams.append('token', useAuthenticationStore().token || '');

  if (fromSwitchInterface) {
    url.searchParams.append('switchinterface', 'true');
  }
  if (activateWarning) {
    url.searchParams.append('activatemsg', 'true');
  }
  return url.toString();
};

export const goToAdminAndLogin = (): void => {
  window.location.href = getSquareAdminUrlForModeratorAndObserver();
};

export const getSwitchInterfaceUrl = (): string => {
  const fromSwitchInterface = true;
  let url: string;
  const role = useAuthenticationStore().tokenInfo?.role;
  if (role === Role.ProfessionalAdmin || role === Role.ClientAdmin || role === Role.ClientEditor || role === Role.Observer) {
    url = getSquareAdminUrlForModeratorAndObserver(fromSwitchInterface);
  } else {
    url = getSquareAdminSignOutAndRedirectUrlForInsitesUser();
  }

  return url;
};

export const getWizardUrl = (activityGuid: string, step: string): string => {
  const additionalParams = {
    activityGuid,
    step,
  };

  const role = useAuthenticationStore().tokenInfo?.role;
  const redirectType = (role === Role.ProfessionalAdmin || role === Role.ClientAdmin || role === Role.ClientEditor || role === Role.Observer) ?
    ResearcherLoginRedirectType.ActivityWizard :
    InSitesSquareLogoutRedirectType.ActivityWizard;

  return getUrl(redirectType, additionalParams);
};

export const getActivityConversationUrl = (activityGuid: string, replyGuid: string) => {
  const additionalParams = {
    activityGuid,
    replyGuid,
  };

  const role = useAuthenticationStore().tokenInfo?.role;
  const redirectType = (role === Role.ProfessionalAdmin || role === Role.ClientAdmin || role === Role.ClientEditor || role === Role.Observer) ?
    ResearcherLoginRedirectType.ActivityConversation :
    InSitesSquareLogoutRedirectType.ActivityConversation;

  return getUrl(redirectType, additionalParams);
};

export const onBehalfOfGuidFromURI = (to?: RouteLocationNormalized): string | undefined => {
  const route = to || router.currentRoute.value;
  return route.query.on_behalf_of?.toString();
};

export const toConversationParamFromURI = (to?: RouteLocationNormalized) => {
  const route = to || router.currentRoute.value;
  const conversationGuid = route.query.go_to_conversation?.toString();
  const redirectType = route.query.redirect_type?.toString();
  return {
    conversationGuid,
    redirectType: redirectType ? Number.parseInt(redirectType, 10) as RedirectForumType : null,
  };
};

const getUrl = (redirectType: number, additionalParams: Record<string, string>): string => {
  const role = useAuthenticationStore().tokenInfo?.role;
  if (role === Role.ProfessionalAdmin ||role === Role.ClientAdmin || role === Role.ClientEditor || role === Role.Observer) {
    // eslint-disable-next-line max-len
    const url = new URL(useSquareStore().adminUrl);
    url.pathname = combineURLPaths(url.pathname, 'researcherlogin');
    url.searchParams.append('token', useAuthenticationStore().token || '');
    url.searchParams.append('switchinterface', 'true');
    url.searchParams.append('redirectType', redirectType.toString());

    for (const key in additionalParams) {
      if (Object.prototype.hasOwnProperty.call(additionalParams, key) && key !== 'token' && key !== 'switchinterface' && key !== 'redirectType') {
        const value = additionalParams[key];
        url.searchParams.append(key, value);
      }
    }

    return url.toString();
  }

  return getSquareAdminSignOutUrl(redirectType, additionalParams);
};

const getSquareAdminSignOutAndRedirectUrlForInsitesUser = () => {
  const redirectType = useSquareStore().info.isCompleted
    ? InSitesSquareLogoutRedirectType.Activities
    : InSitesSquareLogoutRedirectType.SquareInfo;

  return getSquareAdminSignOutUrl(redirectType);
};

const getSquareAdminSignOutUrl = (redirectType: number, additionalParams?: Record<string, string>) => {
  const url = new URL(useSquareStore().adminUrl);
  url.pathname = combineURLPaths(url.pathname, 'squarelogout');
  url.searchParams.append('token', useAuthenticationStore().token || '');
  url.searchParams.append('redirectType', redirectType.toString());

  if (additionalParams) {
    for (const key in additionalParams) {
      if (Object.prototype.hasOwnProperty.call(additionalParams, key) && key !== 'token' && key !== 'redirectType') {
        const value = additionalParams[key];
        url.searchParams.append(key, value);
      }
    }
  }

  return url.toString();
};

export const getCurrentSubdomain = () => {
  const currentUrl = window.location.href;
  // everything between '://' and '.'
  const subdomainRegex = /:\/\/(.*?)\./;
  return currentUrl.match(subdomainRegex)?.[1]?.toLowerCase();
};

interface NavigationData {
  target?: '_self' | '_blank';
  route?: RouteLocationRaw;
  url?: string;
}

const convertToRouteIfInternalUrl = (link: string | RouteLocationRaw | undefined, target?: '_blank' | '_self'): NavigationData | undefined => {
  if(!link){
    return undefined;
  }
  if(typeof link === 'string'){
    try {
      const currentHost = window.location.host;
      const url = new URL(link);
      // internal url
      if (url.host === currentHost) {
        const route = router.resolve(url.pathname);
        return {
          route,
        };
      }
      // external url
      return {
        target,
        url: link,
      };
    } catch {
    // Invalid URL
      return undefined;
    }
  }
  return {
    route: link,
  };
};

export const navigate = (link: string | RouteLocationRaw | undefined,  target?: '_self' | '_blank') => {
  if(!target) {
    target = '_self';
  }

  const navigationData = convertToRouteIfInternalUrl(link, target);
  if(navigationData?.url) {
    window.open(navigationData.url, target);
    return;
  }
  if(navigationData?.route) {
    if(target === '_self'){
      router.push(navigationData.route);
    } else {
      const resolvedRoute = router.resolve(navigationData.route);
      window.open(resolvedRoute.href, target);
    }
  }
};
