import {
  PrepareParticipantForActivationRequest,
  SaveSquareParticipantAvatarResponse,
  UnsubscribeSquareParticipantResponse,
  UnsubscribeSquareParticipantRequest,
  CreateUnsubscribeReasonRequest,
  ReactivateParticipantStatusResponse,
  ReactivateParticipantStatusRequest,
  RegisterSquareParticipantResponse,
  RegisterSquareParticipantRequest,
  CreateSquareParticipantResponse,
  CreateExternalParticipantRequest,
  SendActivationEmailRequest,
  SaveParticipantActivationResponsesRequest,
  ResetPasswordResponse,
  ResolveAppResetPasswordDetailsResponse,
  ResolveAppResetPasswordDetailsRequest,
  ResetPasswordRequest,
  ChangePasswordResponse,
  ChangePasswordRequest,
  ChangeEmailResponse,
  ChangeEmailRequest,
  UpdateSquareParticipantResponse,
  UpdateSquareParticipantDetailRequest,
  SquareParticipantDetailResponse,
  AppLoginResponse,
  AppLoginRequest,
  LinkMobileUserToSquareResponse,
  LinkMobileUserToSquareRequest,
  AppEmailAvailableRequest,
  MobileAppLogoutRequest,
  CreateMobileAppUserResponse,
  CreateMobileAppUserRequest,
  UpdateMemberDetailRequest,
  MemberImportValidationResponse,
  TriggerMassUploadTaskRequest,
  BulkChangeRoleRequest,
  BulkSendActivationRequest,
  RegistrationStatus,
  BulkChangeStatusRequest,
  Segmentation2,
  Segment,
  BulkSegmentUsageRequest,
  BulkChangeSegments,
  GetSquareParticipantGuidsAndUsernamesResponse,
  GetSquareParticipantGuidsAndUsernamesRequest,
  MemberWithReplyImportValidationResponse,
  TriggerCustomActivityUploadTaskRequest,
  RegisterEmailAddressForADActivationResponse,
  SquareParticipantCommunicationEmailValidationResponse,
  SquareParticipantCommunicationEmailValidationRequest,
  SquareParticipantCommunicationEmailValidationCodeResponse,
  SquareParticipantCommunicationEmailValidationCodeRequest,
} from '../../../models/command';
import{ httpService } from '../../../../src/config';
import { DateTime } from 'luxon';
import { AxiosRequestConfig } from 'axios';

export const prepareParticipantForActivation = (request: PrepareParticipantForActivationRequest, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.post<void>(
    '/ParticipantService/PrepareParticipantForActivation',
    request,
    undefined,
    config);

export const saveParticipantAvatar = (config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.post<SaveSquareParticipantAvatarResponse>(
    '/ParticipantService/SaveParticipantAvatar',
    undefined,
    undefined,
    config);

export const logoutOnAllSquares = (config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.post<void>(
    '/ParticipantService/LogoutOnAllSquares',
    undefined,
    undefined,
    config);

export const requestAccountDeletion = (config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.post<void>(
    '/ParticipantService/RequestAccountDeletion',
    undefined,
    undefined,
    config);

export const unsubscribeParticipant = (request: UnsubscribeSquareParticipantRequest, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.post<UnsubscribeSquareParticipantResponse>(
    '/ParticipantService/UnsubscribeParticipant',
    request,
    undefined,
    config);

export const createUnsubscribeReason = (request: CreateUnsubscribeReasonRequest, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.post<void>(
    '/ParticipantService/CreateUnsubscribeReason',
    request,
    undefined,
    config);

export const reactivateParticipant = (request: ReactivateParticipantStatusRequest, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.post<ReactivateParticipantStatusResponse>(
    '/ParticipantService/ReactivateParticipant',
    request,
    undefined,
    config);

export const registerSquareParticipant = (request: RegisterSquareParticipantRequest, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.post<RegisterSquareParticipantResponse>(
    '/ParticipantService/RegisterSquareParticipant',
    request,
    undefined,
    config);

export const createExternalParticipant = (request: CreateExternalParticipantRequest, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.post<CreateSquareParticipantResponse>(
    '/ParticipantService/CreateExternalParticipant',
    request,
    undefined,
    config);

export const createExternalParticipantFromScreener = (request: CreateExternalParticipantRequest, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.post<CreateSquareParticipantResponse>(
    '/ParticipantService/CreateExternalParticipantFromScreener',
    request,
    undefined,
    config);

export const sendActivationEmail = (request: SendActivationEmailRequest, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.post<void>(
    '/ParticipantService/SendActivationEmail',
    request,
    undefined,
    config);

export const setSquareParticipantActivationResponses = (request: SaveParticipantActivationResponsesRequest, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.post<void>(
    '/ParticipantService/SetSquareParticipantActivationResponses',
    request,
    undefined,
    config);

export const resetPassword = (config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.post<ResetPasswordResponse>(
    '/ParticipantService/ResetPassword',
    undefined,
    undefined,
    config);

export const resolveAppResetPasswordDetails = (request: ResolveAppResetPasswordDetailsRequest, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.post<ResolveAppResetPasswordDetailsResponse>(
    '/ParticipantService/ResolveAppResetPasswordDetails',
    request,
    undefined,
    config);

export const appResetPasswordClientApi = (request: ResetPasswordRequest, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.post<ResetPasswordResponse>(
    '/ParticipantService/AppResetPasswordClientApi',
    request,
    undefined,
    config);

export const appResetPassword = (request: ResetPasswordRequest, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.post<ResetPasswordResponse>(
    '/ParticipantService/AppResetPassword',
    request,
    undefined,
    config);

export const changePassword = (request: ChangePasswordRequest, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.post<ChangePasswordResponse>(
    '/ParticipantService/ChangePassword',
    request,
    undefined,
    config);

export const changeParticipantEmail = (request: ChangeEmailRequest, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.post<ChangeEmailResponse>(
    '/ParticipantService/ChangeParticipantEmail',
    request,
    undefined,
    config);

export const updateSquareParticipantDetails = (request: UpdateSquareParticipantDetailRequest, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.post<UpdateSquareParticipantResponse>(
    '/ParticipantService/UpdateSquareParticipantDetails',
    request,
    undefined,
    config);

export const getSquareParticipantDetailsAndUpdate = (config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.post<SquareParticipantDetailResponse>(
    '/ParticipantService/GetSquareParticipantDetailsAndUpdate',
    undefined,
    undefined,
    config);

export const validateLoginAndReturnParticipantSquaresClientApi = (request: AppLoginRequest, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.post<AppLoginResponse>(
    '/ParticipantService/ValidateLoginAndReturnParticipantSquaresClientApi',
    request,
    undefined,
    config);

export const validateLoginAndReturnParticipantSquares = (request: AppLoginRequest, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.post<AppLoginResponse>(
    '/ParticipantService/ValidateLoginAndReturnParticipantSquares',
    request,
    undefined,
    config);

export const linkMobileUserToSquare = (request: LinkMobileUserToSquareRequest, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.post<LinkMobileUserToSquareResponse>(
    '/ParticipantService/LinkMobileUserToSquare',
    request,
    undefined,
    config);

export const fetchParticipantSquaresForMobile = (request: AppLoginRequest, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.post<AppLoginResponse>(
    '/ParticipantService/FetchParticipantSquaresForMobile',
    request,
    undefined,
    config);

export const isEmailAvailableForApp = (request: AppEmailAvailableRequest, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.post<boolean>(
    '/ParticipantService/IsEmailAvailableForApp',
    request,
    undefined,
    config);

export const logoutMobileAppUser = (request: MobileAppLogoutRequest, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.post<boolean>(
    '/ParticipantService/LogoutMobileAppUser',
    request,
    undefined,
    config);

export const createMobileAppUserIfNotExists = (request: CreateMobileAppUserRequest, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.post<CreateMobileAppUserResponse>(
    '/ParticipantService/CreateMobileAppUserIfNotExists',
    request,
    undefined,
    config);

export const updateMember = (request: UpdateMemberDetailRequest, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.post<void>(
    '/ParticipantService/UpdateMember',
    request,
    undefined,
    config);

export const validateExcelFile = (config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.post<MemberImportValidationResponse>(
    '/ParticipantService/ValidateExcelFile',
    undefined,
    undefined,
    config);

export const processUploadedExcelFile = (request: TriggerMassUploadTaskRequest, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.post<string>(
    '/ParticipantService/ProcessUploadedExcelFile',
    request,
    undefined,
    config);

export const bulkChangeRole = (request: BulkChangeRoleRequest, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.post<string>(
    '/ParticipantService/BulkChangeRole',
    request,
    undefined,
    config);

export const bulkSendActivation = (request: BulkSendActivationRequest, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.post<string>(
    '/ParticipantService/BulkSendActivation',
    request,
    undefined,
    config);

export const getParticipantStatusCounts = (request: string[], config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.post<Record<RegistrationStatus, number>>(
    '/ParticipantService/GetParticipantStatusCounts',
    request,
    undefined,
    config);

export const bulkShouldReLogin = (request: BulkChangeStatusRequest, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.post<string>(
    '/ParticipantService/BulkShouldReLogin',
    request,
    undefined,
    config);

export const bulkChangeStatus = (request: BulkChangeStatusRequest, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.post<string>(
    '/ParticipantService/BulkChangeStatus',
    request,
    undefined,
    config);

export const listSegmentations = (config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.post<Segmentation2[]>(
    '/ParticipantService/ListSegmentations',
    undefined,
    undefined,
    config);

export const listSegmentsAndUsage = (request: BulkSegmentUsageRequest, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.post<Segment[]>(
    '/ParticipantService/ListSegmentsAndUsage',
    request,
    undefined,
    config);

export const bulkChangeSegments = (request: BulkChangeSegments, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.post<string>(
    '/ParticipantService/BulkChangeSegments',
    request,
    undefined,
    config);

export const getSquareParticipantGuidsAndUsernames = (request: GetSquareParticipantGuidsAndUsernamesRequest, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.post<GetSquareParticipantGuidsAndUsernamesResponse>(
    '/ParticipantService/GetSquareParticipantGuidsAndUsernames',
    request,
    undefined,
    config);

export const validateCustomExcelFile = (config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.post<MemberWithReplyImportValidationResponse>(
    '/ParticipantService/ValidateCustomExcelFile',
    undefined,
    undefined,
    config);

export const processCustomUploadedExcelFile = (request: TriggerCustomActivityUploadTaskRequest, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.post<string>(
    '/ParticipantService/ProcessCustomUploadedExcelFile',
    request,
    undefined,
    config);

export const registerEmailAddressForAdActivation = (sendEmail: boolean, email?: string, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.post<RegisterEmailAddressForADActivationResponse>(
    '/ParticipantService/RegisterEmailAddressForADActivation',
    undefined,
    { sendEmail, email },
    config);

export const validateCommunicationEmail = (request: SquareParticipantCommunicationEmailValidationRequest, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.post<SquareParticipantCommunicationEmailValidationResponse>(
    '/ParticipantService/ValidateCommunicationEmail',
    request,
    undefined,
    config);

export const validateCommunicationEmailCode = (request: SquareParticipantCommunicationEmailValidationCodeRequest, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.post<SquareParticipantCommunicationEmailValidationCodeResponse>(
    '/ParticipantService/ValidateCommunicationEmailCode',
    request,
    undefined,
    config);

export const platformTourStarted = (config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.post<void>(
    '/ParticipantService/PlatformTourStarted',
    undefined,
    undefined,
    config);
