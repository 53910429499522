import { RouteLocationNormalized, RouteRecordRaw } from 'vue-router';
import { RouteNames } from './route-names.enum';
import { guidRegex } from '@/router/router.constants';
import { getCacheValue } from '@ui/common/utils/cache';
import { useAuthenticationStore } from '@/store/authentication-store';
import { getActivityCompleteRedirect, isSequencedActivitySample } from '@api/services/query/default/ActivityService';
import { getNextSequencedActivityRedirectInfo } from '@api/services/query/default/ActivityService';
import { showSequencedActivityModal } from '@/services/sequence-dialog-service.ts/index';
import { getTodoNotificationsCount } from '@api/services/query/default/SquareService';
import { Notify } from 'quasar';

import router from '@/router';
import { usePagesStore } from '@/store/pages-store';
import { PageType } from '@/common/constants/enums';
import { getLabelValue } from '@ui/services/label-service';

export const autoLoginRoute: RouteRecordRaw = {
  name: RouteNames.AutoLogin,
  path: `/autologin/:sampleGuid(${guidRegex})`,
  component: { template: '<div></div>' },
  meta: {
    requireLogin: false,
  },
  beforeEnter: async (to: RouteLocationNormalized, _from, next): Promise<void> =>  {
    await onAutoLogin(to);
    next();
  },
};

const onAutoLogin = async (to: RouteLocationNormalized) => {
  const sampleGuid = to.params.sampleGuid as string;
  const activityStartedSampleGuid = getCacheValue('activityStartedKey') as string;

  const authStore = useAuthenticationStore();

  if (activityStartedSampleGuid && sampleGuid && activityStartedSampleGuid.toLowerCase() !== sampleGuid.toLowerCase()) {
    await authStore.signout(true);
    return;
  }
  try {
    await authStore.autoLogin(sampleGuid);
    await redirectAfterLogin(sampleGuid);
  } catch (error) {
    // We show the error if there's an actual error message, otherwise we show a generic one
    Notify.create({
      message: (error as Error).message ?? getLabelValue('LabelSomethingWentWrong', 'Something went wrong…'),
      type: 'negative',
      position: 'bottom-right',
      multiLine: true,
    });
    await authStore.signout(true);
  }

};

const redirectAfterLogin = async (sampleGuid: string) => {

  // Will redirect to next activity or home page. If dismissed, go to todo
  const isSequencedActivity = await isSequencedActivitySample(sampleGuid);
  if(isSequencedActivity){
    const nextActivityInSequenceInfo = await getNextSequencedActivityRedirectInfo(undefined, sampleGuid);
    await showSequencedActivityModal(nextActivityInSequenceInfo, true);
    return;
  }

  // If not a sequenced activity, check if survey has a redirect link set up
  const activityCompleteRedirect = await getActivityCompleteRedirect(sampleGuid);
  if(activityCompleteRedirect.activityGuid){
    router.push({ name: RouteNames.ProfileActivity,
      params: { activityGuid: activityCompleteRedirect.activityGuid } });
    return;
  }

  // If participant has other activitieos to do, go to Activities/ToDo
  const todoActivitiesCount = await getTodoNotificationsCount();
  if (todoActivitiesCount > 0) {
    const challengePage = usePagesStore().pageByType(PageType.Activities)[0];
    if(challengePage){
      router.push({ name: RouteNames.ChallengesTodo , params: { pageGuid: challengePage.guid } });
      return;
    }
  }

  // Just go to homepage
  router.push({ name: RouteNames.Home });
};
